<script setup lang="ts">
import type { AcademyLink } from '#academy-virtual/types'
import type { Props as SlideoverProps } from './AcademySlideover.vue'
import Slideover from './AcademySlideover.vue'

export interface Props {
  path: AcademyLink
  slideover?: Omit<SlideoverProps, 'path'>
}

defineProps<Props>()

const isOpen = ref(false)

function close() {
  isOpen.value = false
}
</script>

<template>
  <UiButton
    variant="solid"
    icon="i-heroicons-arrow-right"
    trailing
    label="Readings & Resources"
    v-bind="$attrs"
    @click="isOpen = true"
  />
  <Slideover
    :is-open="isOpen"
    :path="path"
    v-bind="slideover"
    @close="close"
  />
</template>
